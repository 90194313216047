jQuery( document ).ready(function($) {

  const togglePopup = ()=>{
    $('.popup.apple').fadeToggle()
  }

  $('.popup .close.apple').click(function(){
    togglePopup()
  })

  // Newsletter
  $("#oliver-heldens-newsletter-signup-2023-us-rca").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        $('#oliver-heldens-newsletter-signup-2023-us-rca').hide()
        $('.form-alert.error').hide()
        $('.form-alert.success').show()
      },
      error: function (err) {
        $('.form-alert.error').show()
      }
    });
  });

  // Apple code
  $('.apple-btn').click(function(e){
    e.preventDefault()
    togglePopup()
    // Create a new SMEAppleMusic object with your parameters.
    var sm = new SMEAppleMusic({
      ae: {
        ae: "7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd",
        brand_id: "3825369",
        segment_id: "1611674",
        activities: '{"actions":{"presave":96462},"mailing_list_optins":{"a0S1p00000Sm4LYEAZ":96464}}'
      },
      am: {
        dev_token: "",
        save_mode: "library",
        custom_playlist_name: "",
        new_apple_presave: true,
        resources: {
          playlists: [],
          albums: []
        }
      },
      sf: {
        form: "210873",
        default_mailing_list: ""
      },
      smf: {
        campaign_id: "210873",
        campaign_key: "6fa7f5947eec66cd204501a35783ff4e"
      }
    });
    document.getElementById("apple_music_form").addEventListener("submit", function (e) {
      e.preventDefault();
      // Grab the email address value.
      var email = document.getElementById("apple_music_email_address").value;
      // Pass the email address and opt in status
      // This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
      sm.doActions(email, false, ["a0S1p00000Sm4LYEAZ"]).then(function (res) {
        $('.popup form').hide()
        $('.popup .form-alert.error').hide()
        $('.popup .form-alert.success').show()
      }).catch(function (err) {
        $('.popup .form-alert.error').show()
      });
    });
  })

  // Thank you banner
  const url = new URL(window.location.href )
  const urlParam = url.searchParams.get("state")
  const toggleThankYou = () => $('body').addClass('thank-you')
  if(urlParam === 'thank-you'){
    toggleThankYou()
  }

  $('.popup .close.spotify').click(function(){
    $('body').removeClass('thank-you')
  })

});